$(function(){

	// div all
	var all = $('.contadores .todos');

	// contador da div all
	var conter_all = $('#carouselEventos .carousel-item').length;
	var contAtual = $('#contAtual').text();

	$('#carouselEventos').on('slid.bs.carousel',function(){
		contAtual = parseInt(contAtual) + 1;
		
		if(contAtual > conter_all){
			contAtual = 1;
			$('#contAtual').text(contAtual)
			
		}else{
			$('#contAtual').text(contAtual)
		}
	})

	all.text(conter_all);

});