$('body').scrollspy({
	target: '#spyMenu',
	offset: $('.topo').outerHeight()
});

$(window).on('activate.bs.scrollspy', function(e){
	$('#spyMenu .nav-item.active').removeClass('active');
	$('#spyMenu .nav-link.active').parents('.nav-item').addClass('active');
});

$('[data-scroll-to]').click(function(e){
	e.preventDefault();

	var target = $(this).data('scroll-to');
	var targetPosition = $(target).offset();

	$('body, html').animate({
		scrollTop : targetPosition.top
	}, 1000);
	
});