// Banner Video Youtube
var tag = document.createElement('script');
tag.src = "https://www.youtube.com/iframe_api";

var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

var player;

function onPlayerReady(event) {
	event.target.setVolume(100);
	event.target.playVideo();
}

function onPlayerStateChange(state){
	/*
		-1 (unstarted)
		0 (ended)
		1 (playing)
		2 (paused)
		3 (buffering)
		5 (video cued)
	*/
	if(state.data === 1){

		$('.thumbnail').fadeOut(600, function(){
			$(this).remove();
		});

		$('[data-toggle="mute"]').addClass('show');

		if(state.target.isMuted()) {
			$('[data-toggle="mute"] .fas').removeClass('fa-volume-up').addClass('fa-volume-off');
		}else{
			$('[data-toggle="mute"] .fas').removeClass('fa-volume-off').addClass('fa-volume-up');
		}
	}

	if(state.data === 3){
		$('.thumbnail').addClass('loading');
	}

	if(state.data === -1){
		state.target.playVideo();
	}

	// console.log(state)
}

function drawnVideo (){
	var videoYoutubeID = $('#ytb-video').attr('data-video').split('watch?v=')[1];
	player = new YT.Player('ytb-video', {
		videoId: videoYoutubeID,
		playerVars : {
			'autoplay' 			: 1,
			'controls' 			: 0,
			'rel' 				: 0,
			'showinfo' 			: 0,
			'playlist' 			: videoYoutubeID,
			'loop' 				: 1,
			'disablekb' 		: 1,
			'modestbranding' 	: 1,
			'autohide' 			: 1,
			'width'				: window.innerWidth,
			'height'			: window.innerHeight,
			'mute'				: $('#ytb-video').data('mute') || 0
		},
		events : {
			'onReady'		: onPlayerReady,
			'onStateChange'	: onPlayerStateChange
		}
	});
}

$('[data-toggle="mute"]').click(function(e){
	e.preventDefault();

	var mute = $(this).attr('data-mute');

	$(this).attr('data-mute', (mute == 'true' ? 'false' : 'true'));
	$(this).find('.fas').toggleClass('fa-volume-up fa-volume-off')

	if(player.isMuted()){
		player.unMute();
	} else {
		player.mute();
	}
});

if($('#videoDestaque').length > 0){
	function onYouTubeIframeAPIReady(){
		drawnVideo();
	}
}
// Fim Banner Video Youtube